﻿import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/i18n/datepicker-da';
//import 'jquery-ui/ui/i18n/datepicker-en';
import 'jquery-ui/ui/i18n/datepicker-en-GB';
import 'jquery-ui/ui/i18n/datepicker-fr';
import 'jquery-ui/ui/i18n/datepicker-it';
import 'jquery-ui/ui/i18n/datepicker-nb';
import 'jquery-ui/ui/i18n/datepicker-no';
import 'jquery-ui/ui/i18n/datepicker-sv';
import 'jquery-ui/ui/i18n/datepicker-uk';
//import 'jquery-ui/ui/i18n/datepicker-ru';

$.datepicker.regional['en'];
